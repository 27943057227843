<template>
  <b-sidebar
    id="add-new-game-sidebar"
    :visible="isAddNewGameSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-game-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Game
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- First Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="gameData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Last Name -->
          <validation-provider
            #default="validationContext"
            name="slug"
            rules="required"
          >
            <b-form-group
              label="Slug"
              label-for="slug"
            >
              <b-form-input
                id="slug"
                v-model="gameData.slug"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Currency -->
          <validation-provider
            #default="validationContext"
            name="Currency"
            rules="required"
          >
            <b-form-group
              label="Currency"
              label-for="currency"
            >
              <b-form-input
                disabled
                id="currency"
                v-model="gameData.currency"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Operator-->
          <validation-provider
            #default="validationContext"
            name="Operator"
            rules="required|numeric"
          >
            <b-form-group
              label="Operator"
              label-for="operator"
            >
              <b-form-input
                disabled
                id="operator"
                v-model="gameData.operator"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

         
          <!-- Game Is Offered -->
          <validation-provider
            #default="validationContext"
            name="Is Offered"
            rules="required"
          >
            <b-form-group
              label="Is Offered"
              label-for="is_offered"
              :state="getValidationState(validationContext)"
            >
            <b-form-checkbox v-model="checked" name="is_offered" switch>
              <b>{{ checked }}</b>
          </b-form-checkbox>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- <validation-observer
          ref="artworkRules"
          tag="form"
        > -->
          <!-- <b-row>
            <b-col md="6">
              <b-form-group
                label="Thumbnail"
                label-for="thumbnail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Thumbnail"
                  rules=""
                >
                <div class="d-flex flex-wrap">
                    <b-button
                        variant="primary"
                        @click="$refs.refInputEl.click()"
                        >
                        <input
                        ref="refInputEl"
                        type="file"
                        class="d-none"
                        :state="errors.length > 0 ? false:null"
                        @input="inputImageRenderer"
                        >
                        <span class="d-none d-sm-inline">Choose Image</span>
                        <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none text-danger"
                        />
                    </b-button>
                </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
                <div class="d-flex flex-wrap">
                    <b-img
                    fluid
                    ref="previewEl"
                    :src="gameData.thumbnail"
                    />
                </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Thumbnail"
                label-for="thumbnail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Thumbnail"
                  rules=""
                >
                <div class="d-flex flex-wrap">
                    <b-button
                        variant="primary"
                        @click="$refs.refThumbnailInputEl.click()"
                        >
                        <input
                        ref="refThumbnailInputEl"
                        type="file"
                        class="d-none"
                        :state="errors.length > 0 ? false:null"
                        @input="inputThumbnailImageRenderer"
                        >
                        <span class="d-none d-sm-inline">Choose Image</span>
                        <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none text-danger"
                        />
                    </b-button>
                </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
                <div class="d-flex flex-wrap">
                    <b-img
                    fluid
                    ref="previewThumbnailEl"
                    :src="gameData.thumbnail"
                    />
                </div>
            </b-col>
          </b-row> -->
        <!-- </validation-observer> -->
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup,
  BFormInput, BFormInvalidFeedback,
  BButton, BInputGroupPrepend, BInputGroup, BFormCheckbox, BImg, BCol, BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, numeric } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroupPrepend,
    BButton,
    vSelect,
    BInputGroup,
    BFormCheckbox,
    BImg,
    BCol,
    BRow,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewGameSidebarActive',
    event: 'update:is-add-new-game-sidebar-active',
  },
  props: {
    isAddNewGameSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      numeric,
      checked: true,
    }
  },
  methods: {
    validationFormArtWork() {
      return new Promise((resolve, reject) => {
        this.$refs.artworkRules.validate().then(success => {
          if (success) {
            this.submitEventArtworkData()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
  setup(props, { emit }) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      gameData.value.poster = base64
    })
    const refThumbnailInputEl = ref(null)
    const previewThumbnailEl = ref(null)

    const { inputThumbnailImageRenderer } = useInputImageRenderer(refThumbnailInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      gameData.value.thumbnail = base64
    })
    const staffData = JSON.parse(localStorage.getItem('userData'))
    const blankGameData = {
      name: '',
      operator: '',
      currency: 'KES',
      slug: '',
      is_offered: true,
      thumbnail: null,
      priority: 0,
      created_by: staffData.email,
    }

    const gameData = ref(JSON.parse(JSON.stringify(blankGameData)))
    const resetgameData = () => {
      gameData.value = JSON.parse(JSON.stringify(blankGameData))
    }
    // Use toast
    const toast = useToast()

    const onSubmit = () => {
      store.dispatch('games/addGame', gameData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-game-sidebar-active', false)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Game Account Created',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Game account has been created successfully',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetgameData)

    return {
      gameData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      inputImageRenderer,
      inputThumbnailImageRenderer,
      previewThumbnailEl,
      refThumbnailInputEl,
      previewEl,
      refInputEl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-game-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
